.range-input-container {
  $barWidth: 4px;
  $handleWidth: 20px;
  
  height: $barWidth !important;
  padding: 0 !important;

  .rail {
    border: none;
    background: var(--azure);
    opacity: 1;
    box-shadow: none;
    padding: 0;
    height: 100%;
  }

  .track {
    background: var(--canary);
    height: 100%;
  }

  .handle {
    background-color: var(--canary);
    width: $handleWidth;
    height: $handleWidth;
    margin-top: ($barWidth - $handleWidth) / 2;
    margin-left: -($handleWidth / 2);
    
    &::before, &::after {
      display: none;
    }
  }
}