.anchor-button-container {
  height: 2.5rem;
  display: flex;
  align-items: center;
  border-radius: .25rem;
  color: var(--canary);
  justify-content: center;
  text-decoration: none;

  &:hover {
    color: white;
  }

  span {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}