@import "./mixins.scss";

:root {
  --background: #080C23;
  --canary: #FFC700;
  --canary--rgb: 255, 199, 0;
  --turbo-blue: #06CCF8;
  --azure: #0D38D4;
  --azure-glass: #06103D;
  --loop: #F13BF4;
  --one-shot: #FF7A00;
  --track: linear-gradient(45deg, var(--loop),var(--one-shot));
  --charcoal: #3A3D4F;
  --font--signika: 'Signika Negative', sans-serif;
  --font--shrikhand: 'Shrikhand';
  --max-width: 560px;
  background: var(--background);
  color: var(--turbo-blue);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: normal;
}

body {
  max-width: var(--max-width);
  margin: 0 auto;
  overflow-y: scroll;
}

h1 {
  font-family: var(--font--signika);
  font-size: 2.5rem;
  font-weight: normal;
}

h4 {
  @include heading;
}

body, p, span {
  @include body;
}

a {
  color: var(--canary);
  text-decoration: none;

  &:hover {
    color: white;
  }
}

button {
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
}

.App {
  // Create a new stacking context
  isolation: isolate;
}