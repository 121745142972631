@import "../mixins.scss";

.about-page-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--background);
  z-index: 1;
  // 4rem is the height of the header
  padding: 4rem 8px 8rem;
  overflow: auto;

  h4 {
    @include gradientText;
  }

  p {
    margin-top: .75rem;
    line-height: 1.5rem;
  }

  ul {
    margin-top: .75rem;
    padding-left: 1.25rem;
    list-style-image: url('../assets/bullet.svg');

    li {
      padding-left: unset;
    }
  }

  section {
    margin-top: 2.5rem;
    max-width: calc(var(--max-width) - 16px);
    margin-left: auto;
    margin-right: auto;
  }

  .button-group {
    margin-top: .75rem;
    display: flex;

    img {
      height: 24px;
      margin-right: 8px;
    }

    .github-button,
    .ko-fi-button {
      flex: 1;
      span {
        width: auto;
      }
    }

    .github-button{
      img {
        height: 20px;
      }
    }
  }
}