@import '../../mixins.scss';

.search-item-container {
  margin: 0;
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 3rem;
  cursor: pointer;
  .loading {
    text-align: center;
  }

  h4 {
    display: inline-block;
    color: var(--canary);
    line-height: 1.75rem;
  }

  &:not(.loading):hover h4 {
    color: white;
  }

  .pack-count {
    @include gradientText;
    margin-left: 8px;

    .pack-count__number {
      border: 1px solid var(--turbo-blue);
      border-radius: 4px;
      padding: 0 3px;
      margin-right: 2px;
    }
  }
}
