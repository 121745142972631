@import "../../mixins.scss";

.group-container {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--turbo-blue);
  border-radius: 3px;
  padding: 4px;
  position: relative;
  margin-top: 28px;
  margin-bottom: 4px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: -28px;
    left: 2px;
    width: calc(100% - 4px);
  }
}
