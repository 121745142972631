@mixin body {
  font-family: var(--font--signika);
  font-size: 1.125rem;
}

@mixin heading {
  font-family: var(--font--shrikhand);
  font-weight: normal;
  font-size: 1.125rem;
  font-kerning: none;
}

@mixin gradientText {
  /* if we can clip, do it */
  -webkit-text-fill-color: transparent;
  background: var(--track);
  background-clip: text;
  color: var(--loop);
}