.group-controls-container {
  margin-top: 16px;
  display: flex;
  align-items: center;

  &.space-between {
    justify-content: space-between;
  }

  .fixed-width {
    justify-content: left;
    span {
      text-align: left;
      min-width: 7ch;
    }
  }

  .track-list-group-summary-container {
    margin-left: 16px;
  }
}