@import "../../mixins.scss";

.search-dropdown-container {
  border-radius: 20px 8px 8px 8px;
  background: var(--azure-glass);
  display: flex;
  flex-direction: column;

  .message {
    text-align: center;
    padding: 40px 0;
    height: 190px;
    margin-top: 4px;
  }

  .tag-list-container {
    height: 190px;
    margin-top: 4px;
    padding: 20px 0;
    text-align: center;
  }

  .results {
    overflow-y: auto;
    padding: 0 8px 32px 8px;
    height: 190px;
    margin-top: 4px;
  }
}