.one-shot-range-container {
  // Shift controls up slightly to reduce their
  //  height and make the row fit into the 56px
  //  row
  margin-top: -2px;

  .sentence {
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
  }

  .number {
    color: var(--one-shot);
    font-size: 1rem;
  }

  .wick {
    margin-top: 4px;
    width: 100%;
    height: 2px;
    opacity: 0;
    background-color: var(--one-shot);
    transform-origin: left;

    &.visible {
      opacity: 1;
    }
  }
}