.track-item-container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .row {
    display: flex;
  }

  .torch {
    width: 56px;

    & .torch__handle {
      margin-top: 4px;
      height: 56px;
    }
  }

  .track__body {
    flex: 1;
    margin-left: 4px;
    min-width: 0;
    position: relative;
  }

  header {
    display: flex;
    align-items: center;

    .name-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 0;
    }

    h4 {
      color: var(--turbo-blue);
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.expanded {
        text-overflow: unset;
        white-space: normal;
        overflow: visible;
      }
    }
  }

  .track-controls {
    display: flex;
    margin-top: 4px;
  
    .volume-controls-container {
      flex: 1;
      margin-right: 4px;
    }
  }
  .tag-container.clickable:not(:last-child) {
    margin-right: 2px;
    margin-bottom: 4px;
  }
}
