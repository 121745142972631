.tag-container {
  display: inline-flex;
  justify-content: center;
  padding-left: 8px;

  .trailing-icon {
    height: 20px;
    margin-left: 2px;
    position: relative;
    // Nudge icons down a little
    top: 1px;
  }

  &.clickable {
    color: var(--canary);
    cursor: pointer;
    padding: 12px 8px;
    border-radius: 50px;
    background: var(--background);
    border: 1px solid var(--azure);

    &:hover {
      color: white;
    }
  }

  &.tag--loop {
    color: var(--loop);
  }
  
  &.tag--one-shot {
    color: var(--one-shot);
  }
}