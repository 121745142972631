.track-list-group-summary-container {
  flex: 1;
  display: flex;
  justify-content: left;
  align-items: center;

  @keyframes flicker-small {
    100% {
      background-position: -28px * 8;
    }
  }

  .flame {
    display: inline-block;
    z-index: -1;
    width: 28px;
    height: 37px;
    background-image: url("../assets/flame.svg");
    background-size: contain;

    &.playing {
      height: calc(1.3333 * 28px); // Flame animation has an exact ratio of 3:4
      background-image: url("../assets/flame-animation.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: auto 100%;
      animation: flicker-small 1s steps(8) infinite;
    }
  }

  label {
    position: relative;
    top: 4px;
    margin-right: 12px;
    margin-left: 2px;
  }
}
