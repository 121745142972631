.flame-button-container {
  position: relative;
  height: 3.5rem;
  width: 3.5rem;
  isolation: isolate;

  &:disabled {
    cursor: not-allowed;
    
    img, span {
      opacity: .25;
    }
  }

  &:hover:not(:disabled) img {
    transform: translateX(-50%) scale(1.1);
  }

  @keyframes load {
    100% { background-position: -40px * 60; }
  }

  .icon {
    position: absolute;
    width: 2.25rem;
    left: 50%;
    top: .75rem;
    transform: translateX(-50%);
    z-index: 1;
  }
  .loading {
    position: absolute;
    width: 40px;
    height: 12.5px;
    background-image: url('../../assets/loading-animation.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto 100%;
    animation: load 1s steps(60) infinite;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes flicker {
    100% { background-position: -56px * 8; }
  }

  .background {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 56px;
    height: 74px;
    background-image: url('../../assets/flame.svg');

    &.playing {
      height: 133.33%; // Flame animation has an exact ratio of 3:4
      background-image: url('../../assets/flame-animation.png');
      background-repeat: no-repeat;
      background-position: left center;
      background-size: auto 100%;
      animation: flicker 1s steps(8) infinite;
    }
  }
}