@import "../mixins.scss";

.editable-header-container {
  display: flex;
  align-items: center;
  flex: 1;

  .static-text,
  input {
    @include body;
    padding: 0.5rem 4px;
    font-size: 1rem;
    background: var(--background);
    color: var(--turbo-blue);
    // To avoid a slight shift when the input's border is shown,
    //  add a transparent one to the static text version as well
    border: 1px solid transparent;
    color: var(--canary);
  }

  .static-text {
    cursor: pointer;
    &:hover {
      color: white;
    }
  }

  input {
    border: 1px solid var(--canary);
    border-radius: 2px;
  }
}
