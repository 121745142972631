.default-button-container {
  height: 3.5rem;
  padding: 0 .5rem;
  display: flex;
  align-items: center;
  border-radius: .25rem;
  color: var(--canary);
  background: var(--background);
  justify-content: center;

  &:hover:not(:disabled) {
    color: white;
    img {
      transform: scale(1.1);
    }
  }

  &:disabled {
    cursor: not-allowed;
    
    img, span {
      opacity: .25;
    }
  }

  &.icon-only {
    padding: .5rem;
    width: 3.5rem;

    img {
      height: 2.5rem;
    }
  }

  &.round {
    border-radius: 3.5rem;
  }

  &.full-width {
    width: 100%;
  }

  &.active {
    background-color: var(--azure);
    border-color: var(--turbo-blue);
  }

  img {
    height: 2rem;
  }

  span:nth-child(2) {
    margin-left: 4px;
  }
}