@import "../mixins.scss";

.search-field-container {
  position: relative;
  display: flex;
  align-items: center;
  --border-width: 2px;

  .icon {
    position: absolute;
    height: 2rem;
    left: .5rem;
    // Center vertically, then nudge a bit up
    top: 50%;
    transform: translateY(calc(-50% - 2px));
  }

  input {
    background: none;
    --icon-size: 2rem;
    height: 3.5rem;
    flex: 1;
    border-radius: 8px;
    border: 2px solid var(--canary);
    // Required for rounded gradient border
    border-color: transparent;
    background-image: linear-gradient(var(--azure-glass), var(--azure-glass)), var(--track);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    padding: .25rem;
    padding-left: calc(.5rem + var(--icon-size) + .25rem);
    color: var(--turbo-blue);
    @include body;
    
    &::placeholder {
      @include body;
      font-style: italic;
      color: rgba(var(--canary--rgb), 70%);
      opacity: .5;
    }
  }

  .collapse-search-button {
    background: none;
    height: 3.5rem;
  }
}