@import "../mixins.scss";

.app-header-container {
  display: flex;
  align-items: center;
  padding: .5rem;
  justify-content: space-between;

  .logo {
    pointer-events: none;
    height: 3rem;
  }

  &.about-open {
    position: fixed;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    background: var(--background);
    max-width: var(--max-width);
  }
}