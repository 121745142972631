@import "../../mixins.scss";

.track-source-container {
  padding-left: 12px;
  padding-top: 4px;

  .title {
    display: flex;
  }

  label {
    margin-right: 8px;
    color: var(--azure);
    @include heading;
  }

  p {
    word-wrap: break-word;
    margin-bottom: -8px;
  }
  
  .filler {
    flex: 1;
    min-width: 0;
    height: .75rem;
    border-right: 1px solid var(--azure);
    border-bottom: 1px solid var(--azure);
    margin-right: 1.75rem; // Half the 'source' button's width
    margin-left: 8px;
  }
}