.volume-controls-container {
  display: flex;
  align-items: center;

  .range-slider {
    flex: 1;
    margin: 0.75rem 0 0.75rem 12px;
    max-width: 200px;
  }

  .icon {
    height: 1.5rem;
    img {
      height: 1.5rem;
    }
  }
}
